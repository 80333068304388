// Here you can add other styles
.app-table-content-list {
    text-align: start;
    vertical-align: middle;
}

.app-table-header {
    text-align: start;
}

.card-header-text {
    font-size: 1.5em;
    font-weight: 500;
}

.details-status-badge {
    width: fit-content;
    padding: .65rem 1.6rem;
    font-size: 1.1rem;
}

.accordion-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.accordion-button:focus {
    box-shadow: none;
    border-color: var(--cui-input-focus-border-color, #B1B7C1);
}

.accordion-button:not(.collapsed) {
    color: #000;
    background-color: #E8E8E8;
    border-color: #E8E8E8;
}